import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MetricMaker from './MetricMaker';

const useStyles = makeStyles((theme) => ({
  single: {
    // minWidth: '500px',
  },
}));

function Single({ effect_data, control_data, authUser }) {
  const classes = useStyles();
  const Metric = MetricMaker({ effect_data, control_data, authUser, xs: 6 });

  if (!effect_data) return null;

  return (
    <div className={classes.single}>
      <Grid spacing={2} container>
        <Metric
          title="Email Addresses"
          tooltip="The number of email addresses included in this summary."
          base={(effect_data.emails || 0).toLocaleString()}
          hideLift
        />
        <Metric
          title="Customers"
          tooltip="The number of customers associated to the email addresses included in this summary. If a customer has more than one email address and not every email address is in the summary, the customer will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.customers || 0).toLocaleString()}
          hideLift
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Orders"
          tooltip="The number of orders placed by customers with these email addressess. If a customer has more than one email address and not every email address is in the summary, that customers orders will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.orders || 0).toLocaleString()}
          valComputer={(x) => x.orders}
          varComputer={(x) => (x.orders / x.emails) * (1 - x.orders / x.emails)}
          varValComputer={(x) => x.orders / x.emails}
          countComputer={(x) => x.emails}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Order Rate"
          tooltip="The ratio of orders to emails as a percentage."
          base={(effect_data.orders / effect_data.emails).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.orders / x.emails}
          varComputer={(x) => (x.orders / x.emails) * (1 - x.orders / x.emails)}
          countComputer={(x) => x.emails}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Had Order"
          tooltip="The number of email addressess with a customer that had at least one order associated to them. If a customer has more than one email address and not every email address is in the summary, that customers orders will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.had_order || 0).toLocaleString()}
          valComputer={(x) => x.had_order}
          varComputer={(x) => (x.had_order / x.emails) * (1 - x.had_order / x.emails)}
          varValComputer={(x) => x.had_order / x.emails}
          countComputer={(x) => x.emails}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Had Order Rate"
          tooltip="The percentage of email address with a customer that had at least one order associated to them. If a customer has more than one email address and not every email address is in the summary, that customers orders will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.had_order / effect_data.emails).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.had_order / x.emails}
          varComputer={(x) => (x.had_order / x.emails) * (1 - x.had_order / x.emails)}
          countComputer={(x) => x.emails}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Revenue"
          tooltip="The amount of revenue from customers with these email adddresses. If a customer has more than one email address and not every email address is in the summary, that customers orders will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.amount || 0).toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          valComputer={(x) => x.amount}
          varComputer={(x) => x.amount_per_email_var}
          varValComputer={(x) => x.amount / x.emails}
          countComputer={(x) => x.emails}
          totalFormater={(x) => x.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          showTotalLift
        />
        <Metric
          title="Revenue Per Email Address"
          tooltip="The total revenue divided by the number of email addresses. If a customer has more than one email address and not every email address is in the summary, that customers orders will only be counted according to the fraction of email addresses that do match."
          base={(effect_data.amount / effect_data.emails).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
          valComputer={(x) => x.amount / x.emails}
          varComputer={(x) => x.amount_per_email_var}
          countComputer={(x) => x.emails}
        />
      </Grid>
      <Divider />
    </div>
  );
}

export default function UnattributedResults({ data, hideTitles, authUser }) {
  const classes = useStyles();
  const getTitle = (x) => (hideTitles ? null : <Typography variant="h6">{x}</Typography>);
  if (data.control)
    return (
      <Grid container spacing={8} className={classes.root}>
        <Grid item lg={6} xs={12}>
          {getTitle('Test')}
          <Single effect_data={data['effect']} control_data={data['control']} authUser={authUser} />
        </Grid>
        <Grid item lg={6} xs={12}>
          {getTitle('Control')}
          <Single effect_data={data['control']} authUser={authUser} />
        </Grid>
      </Grid>
    );
  else return <Single effect_data={data} />;
}
