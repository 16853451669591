import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DayCard from './DayCard';
import EventCard from './EventCard';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import EmailResults from './EmailResults';
import CreativeList from './CreativeList';
import Button from '@material-ui/core/Button';
import moment from 'moment';

function AllDays({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Days';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/list_days?${makeQuery(params)}`;

  const [{ day_data, summary_data }, loaded, errorLoading] = useFetch(url, [], authUser);
  function Display() {
    if (loaded) {
      const last_updated = (summary_data.effect || summary_data.control || summary_data).last_updated;
      return (
        <div>
          {last_updated ? (
            <span>Last Updated: {moment(last_updated + '+00:00').format('dddd, MMMM Do YYYY, h:mm:ss a')}</span>
          ) : null}
          <EmailResults data={summary_data} authUser={authUser} hideEffectiveness directTitle={params.primary ? "Primary" : params.notPrimary ? 'Not Primary' : Object.keys(params.contentBlocks).length > 0 ? 'Content Block Selection' : 'Primary'} allTitle="All" />
          {day_data.map((data, index) => (
            <DayCard day_data={data} key={index} authUser={authUser} hideEffectiveness />
          ))}
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load day data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Button variant="contained" href={`${BASE_URL}/export_list_days?${makeQuery(params)}`}>
        Export
      </Button>
      <Display />
    </div>
  );
}

function OneDay({ match, history, authUser }) {
  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const searchParams = new URLSearchParams(makeQuery(params));
  searchParams.set('id', match.params.id);
  const url = `${BASE_URL}/get_day?${searchParams.toString()}`;
  const [{ day_data, event_data, creative_data }, dayLoaded, errorLoading] = useFetch(url, [], authUser);
  document.title = `Day ${match.params.id}`;

  function Display() {
    if (dayLoaded) {
      return (
        <div>
          <DayCard day_data={day_data} hideHistoryButton authUser={authUser} hideEffectiveness />
          {event_data.map((data, index) => (
            <EventCard event_data={data} key={index} authUser={authUser} hideEffectiveness params={params}/>
          ))}
          <CreativeList creative_data={creative_data} params={makeQuery(params)} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load data for this day.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} hideDates hideEventTags authUser={authUser} />
      <Display />
    </div>
  );
}

export default function Days({ match, authUser }) {
  return (
    <div>
      <Route path={`${match.path}/:id`} render={(props) => <OneDay {...props} authUser={authUser} />} />
      <Route exact path={match.path} render={(props) => <AllDays {...props} authUser={authUser} />} />
    </div>
  );
}
