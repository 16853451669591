// @format
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import EventCard from './EventCard';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import EmailResults from './EmailResults';
import CreativeList from './CreativeList';
import ExportFormatDialog from './ExportFormatDialog';
import Button from '@material-ui/core/Button';
import moment from 'moment';

function AllEvents({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Events';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/list_events?${makeQuery(params)}`;
  const [{ event_data, summary_data }, loaded, errorLoading] = useFetch(url, [], authUser);

  const [exportFormatOpen, setExportFormatOpen] = React.useState(false);
  const doExport = (export_format) => {
    setExportFormatOpen(false);
    window.location.href = `${BASE_URL}/export_list_events?${makeQuery(
      params
    )}&r=${Math.random()}&export_format=${export_format}`;
  };

  function Display() {
    if (loaded) {
      const table_type = summary_data.effect || summary_data.control ? 'event_comparison' : 'event';
      const last_updated = (summary_data.effect || summary_data.control || summary_data).last_updated;
      return (
        <div>
          <ExportFormatDialog
            open={exportFormatOpen}
            params={params}
            authUser={authUser}
            table_type={table_type}
            onCancel={() => setExportFormatOpen(false)}
            onFinish={doExport}
          />
          <Button variant="contained" onClick={() => setExportFormatOpen(true)}>
            Export
          </Button>
          {last_updated ? (
            <p>Last Updated: {moment(last_updated + '+00:00').format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          ) : null}
          <EmailResults
            data={summary_data}
            authUser={authUser}
            hideEffectiveness
            directTitle={
              params.primary
                ? 'Primary'
                : params.notPrimary
                ? 'Not Primary'
                : Object.keys(params.contentBlocks).length > 0
                ? 'Content Block Selection'
                : 'Primary'
            }
            allTitle="All"
          />
          {event_data.map((data, index) => (
            <EventCard event_data={data} key={index} authUser={authUser} hideEffectiveness params={params} />
          ))}
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load event data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Display />
    </div>
  );
}

function OneEvent({ match, history, authUser }) {
  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const searchParams = new URLSearchParams(makeQuery(params));
  searchParams.set('id', match.params.id);
  const url = `${BASE_URL}/get_event?${searchParams.toString()}`;
  const [{ event_data, creative_data }, eventLoaded, errorLoading] = useFetch(url, [], authUser);

  useEffect(() => {
    if (eventLoaded) document.title = `Event ${(event_data.control || event_data.effect || event_data)['title']}`;
    else document.title = `Event ${match.params.id}`;
  }, [event_data, match.params.id, eventLoaded]);

  function Display() {
    if (eventLoaded) {
      return (
        <div>
          <EventCard event_data={event_data} hideHistoryButton authUser={authUser} hideEffectiveness />
          <CreativeList creative_data={creative_data} params={makeQuery(params)} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load data for this event.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} hideDates hideEventTags authUser={authUser} />
      <Display />
    </div>
  );
}

export default function Events({ match, authUser }) {
  return (
    <div>
      <Route path={`${match.path}/:id`} render={(props) => <OneEvent {...props} authUser={authUser} />} />
      <Route exact path={match.path} render={(props) => <AllEvents {...props} authUser={authUser} />} />
    </div>
  );
}
