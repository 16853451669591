// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { AudienceDisplay } from './Audience';
import { EventTagDisplay } from './EventTag.js';
import { CreativeTagDisplay } from './CreativeTag.js';
import { ExperimentDisplay } from './Experiment.js';
import { ComparisonDisplay } from './Comparison';
import { ContentBlockDisplay } from './ContentBlock';
import { DateRangeDisplay } from './DateRange';
import QueryEditDialog from './QueryEditDialog';
import moment from 'moment';
import useFetch from '../useFetch';
import { BASE_URL } from '../Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export function parseQuery(history) {
  let params = new URLSearchParams(history.location.search);
  const client = params.get('client_id');
  const environment = params.get('environment');
  if (!client || !environment) {
    history.push('/');
  }

  const makeAudienceDict = (paramName) => {
    return (params.get(paramName) || '').split(',').reduce((obj, audience_id) => {
      if (parseInt(audience_id) > 0) obj[audience_id] = true;
      return obj;
    }, {});
  };

  const start_date = params.get('start_date');
  const excluded_dates = params.get('excluded_dates');
  const end_date = params.get('end_date');
  const primary = params.get('primary') === 'true';
  const notPrimary = params.get('notPrimary') === 'true';
  const eligibility = params.get('eligibility');
  return {
    audience: makeAudienceDict('audience_ids'),
    comparison: {
      effect: makeAudienceDict('effect_audience_ids'),
      control: makeAudienceDict('control_audience_ids'),
    },
    start_date: start_date ? moment(start_date) : moment().subtract(365, 'days'),
    end_date: end_date ? moment(end_date) : moment(),
    excluded_dates: excluded_dates ? excluded_dates.split(',').map((x) => moment(x).toDate()) : [],
    client_id: params.get('client_id'),
    environment: params.get('environment'),
    experiments: makeAudienceDict('experiments'),
    tags: makeAudienceDict('tags'),
    excluded_tags: makeAudienceDict('excluded_tags'),
    creative_tags: makeAudienceDict('creative_tags'),
    excluded_creative_tags: makeAudienceDict('excluded_creative_tags'),
    primary: primary,
    notPrimary: notPrimary,
    eligibility: eligibility,
    contentBlocks: primary || notPrimary ? {} : makeAudienceDict('contentBlocks'),
  };
}

export function makeQuery({
  client_id,
  environment,
  audience,
  comparison: { effect, control },
  start_date,
  end_date,
  excluded_dates,
  tags,
  excluded_tags,
  creative_tags,
  excluded_creative_tags,
  contentBlocks,
  primary,
  notPrimary,
  eligibility,
  experiments,
}) {
  const getSelected = (param) => Object.keys(param).filter((x) => param[x]);

  const searchParams = new URLSearchParams('');
  searchParams.set('client_id', client_id);
  searchParams.set('environment', environment);
  searchParams.set('audience_ids', getSelected(audience));
  searchParams.set('control_audience_ids', getSelected(control));
  searchParams.set('effect_audience_ids', getSelected(effect));
  searchParams.set('start_date', start_date.format('YYYY-MM-DD'));
  searchParams.set('end_date', end_date.format('YYYY-MM-DD'));
  searchParams.set(
    'excluded_dates',
    excluded_dates.map((x) => moment(x).format('YYYY-MM-DD'))
  );
  searchParams.set('experiments', getSelected(experiments));
  searchParams.set('tags', getSelected(tags));
  searchParams.set('excluded_tags', getSelected(excluded_tags));
  searchParams.set('creative_tags', getSelected(creative_tags));
  searchParams.set('excluded_creative_tags', getSelected(excluded_creative_tags));
  searchParams.set('contentBlocks', primary || notPrimary ? '' : getSelected(contentBlocks));
  searchParams.set('primary', primary || '');
  searchParams.set('notPrimary', notPrimary || '');
  searchParams.set('eligibility', eligibility || '');
  return searchParams.toString();
}

export function QueryBuilder({ params, setParams, hideDates, hideEventTags, hideContentBlock, audienceUrl, authUser }) {
  const classes = useStyles();

  const audienceChoices = useFetch(
    `${BASE_URL}/${audienceUrl || 'list_audiences'}?client_id=${params.client_id}&environment=${params.environment}`,
    [],
    authUser
  )[0];
  const creativeTagChoices = useFetch(
    `${BASE_URL}/list_creative_tags?client_id=${params.client_id}&environment=${params.environment}`,
    [],
    authUser
  )[0];
  const eventTagChoices = useFetch(
    `${BASE_URL}/list_event_tags?client_id=${params.client_id}&environment=${params.environment}`,
    [],
    authUser
  )[0];
  const experimentChoices = useFetch(
    `${BASE_URL}/list_experiments?client_id=${params.client_id}&environment=${params.environment}`,
    [],
    authUser
  )[0];
  const contentBlockChoices = useFetch(
    `${BASE_URL}/list_content_blocks?client_id=${params.client_id}&environment=${params.environment}`,
    [],
    authUser
  )[0];

  const getSelected = (param) => audienceChoices.filter((x) => param[x.id]);
  const selectedAudiences = getSelected(params.audience);
  const selectedControl = getSelected(params.comparison.control);
  const selectedEffect = getSelected(params.comparison.effect);

  const [changeQueryOpen, setChangeQueryOpen] = React.useState(false);

  const [midEditParms, setMidEditParams] = React.useState(params);

  const handleQueryEditSave = () => {
    setChangeQueryOpen(false);
    setParams(midEditParms);
  };
  const handleQueryEditCancel = () => {
    setChangeQueryOpen(false);
    setMidEditParams(params);
  };

  return (
    <Paper className={classes.root}>
      <div onClick={() => setChangeQueryOpen(true)}>
        <ExperimentDisplay experimentChoices={experimentChoices} experiments={params.experiments} />
        <ContentBlockDisplay
          primary={params.primary}
          notPrimary={params.notPrimary}
          contentBlockChoices={contentBlockChoices}
          contentBlocks={params.contentBlocks}
          hideContentBlock={hideContentBlock}
        />
        <CreativeTagDisplay tagChoices={creativeTagChoices} tags={params.creative_tags} />
        <CreativeTagDisplay tagChoices={creativeTagChoices} tags={params.excluded_creative_tags} excluded/>
        <EventTagDisplay tagChoices={eventTagChoices} tags={params.tags} />
        <EventTagDisplay tagChoices={eventTagChoices} tags={params.excluded_tags} excluded />
        <AudienceDisplay selectedAudiences={selectedAudiences} />
        <ComparisonDisplay selectedEffect={selectedEffect} selectedControl={selectedControl} />
        {!hideDates && (
          <DateRangeDisplay
            start_date={params.start_date}
            end_date={params.end_date}
            excluded_dates={params.excluded_dates}
          />
        )}
        <Button startIcon={<EditIcon />} color="primary" onClick={() => setChangeQueryOpen(true)}>
          Edit Query
        </Button>
      </div>
      <QueryEditDialog
        open={changeQueryOpen}
        onSave={handleQueryEditSave}
        onCancel={handleQueryEditCancel}
        params={midEditParms}
        setParams={setMidEditParams}
        hideDates={hideDates}
        hideEventTags={hideEventTags}
        hideContentBlock={hideContentBlock}
        audienceChoices={audienceChoices}
        eventTagChoices={eventTagChoices}
        creativeTagChoices={creativeTagChoices}
        contentBlockChoices={contentBlockChoices}
        experimentChoices={experimentChoices}
      />
    </Paper>
  );
}
