//@format
import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import EmailResults from './EmailResults';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ExportFormatDialog from './ExportFormatDialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  cardContent: {
    width: '100%',
  },
}));

function TagList({ tag_data, authUser }) {
  const [showControlEffect, setShowControlEffect] = React.useState('total');
  const dataToShow = tag_data.control ? tag_data[showControlEffect] : tag_data;

  const getTabs = () => {
    if (tag_data.control)
      return (
        <Tabs value={showControlEffect} onChange={(event, newValue) => setShowControlEffect(newValue)} centered>
          <Tab label="All" value="total" />
          <Tab label="Test" value="effect" />
          <Tab label="Control" value="control" />
        </Tabs>
      );
    else return null;
  };
  const classes = useStyles();
  return (
    <div>
      {getTabs()}
      {dataToShow.map((data, index) => (
        <Card className={classes.card} key={index} raised>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5">
              {data.tag}
            </Typography>
            <h3>Effectiveness: {data.effectiveness ? data.effectiveness.toLocaleString() : ''}</h3>
            <EmailResults
              key={index}
              data={data}
              authUser={authUser}
              hideEffectiveness
              notPrimaryClicks
              objectTitle="this tag"
            />
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default function TagExposure({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Tag Exposure';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/get_tag_exposure?${makeQuery(params)}`;

  const [summary, loaded, errorLoading] = useFetch(url, [], authUser);

  const [exportFormatOpen, setExportFormatOpen] = React.useState(false);
  const doExport = (export_format) => {
    setExportFormatOpen(false);
    window.location.href = `${BASE_URL}/export_tag_exposure?${makeQuery(
      params
    )}&r=${Math.random()}&export_format=${export_format}`;
  };

  function Display() {
    if (loaded) {
      return (
        <div>
          <ExportFormatDialog
            open={exportFormatOpen}
            params={params}
            authUser={authUser}
            table_type="tag_exposure"
            onCancel={() => setExportFormatOpen(false)}
            onFinish={doExport}
          />
          <Button variant="contained" onClick={() => setExportFormatOpen(true)}>
            Export
          </Button>
          <TagList tag_data={summary} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load tag exposure data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Display />
    </div>
  );
}
