// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EmailResults from './EmailResults';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  cardContent: {
    width: '100%',
  },
}));

function Title({ event_obj }) {
  return (
    <div>
      <Typography gutterBottom variant="h5">
        {event_obj.title}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Short UUID: ${event_obj.short_uuid}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`ID: ${event_obj.event_id}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Send Datetime: ${event_obj.send_datetime}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Status: ${event_obj.status}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Tags: ${event_obj.tags}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Experiments: ${event_obj.experiments}`}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        {`Template: ${event_obj.template}`}
      </Typography>
    </div>
  );
}

function Actions({ event_obj, hideHistoryButton }) {
  let params = new URLSearchParams(window.location.search);

  return (
    <CardActions>
      <Button target="_blank" color="secondary" href={`https://app.coherentpath.com/event/${event_obj.short_uuid}`}>
        <LaunchIcon />
        Content Library
      </Button>
      {hideHistoryButton ? null : (
        <Button color="secondary" component={Link} to={`/events/${event_obj.event_id}?${params.toString()}`}>
          Details
        </Button>
      )}
    </CardActions>
  );
}

export default function EventCard({
  event_data,
  hideHistoryButton,
  authUser,
  hideEffectiveness,
  notPrimaryClicks,
  params,
}) {
  const classes = useStyles();
  const event_obj = event_data.control || event_data.effect || event_data;
  return (
    <Card className={classes.card} raised>
      <CardContent className={classes.cardContent}>
        <Title event_obj={event_obj} />
        <EmailResults
          data={event_data}
          authUser={authUser}
          hideEffectiveness={hideEffectiveness}
          allTitle={notPrimaryClicks ? 'Emails containing this creative' : 'All'}
          directTitle={notPrimaryClicks ? 'This Creative' : 'Primary'}
          objectTitle="This Creative"
        />
        <Actions event_obj={event_obj} hideHistoryButton={hideHistoryButton} />
      </CardContent>
    </Card>
  );
}
