import frontgate_logo from './Frontgate Logo.png';
import backcountry_logo from './backcountry_logo.png';
import llbean_logo from './LL-Bean-Logo.png';
import grainger_logo from './grainger_logo.jpg';
import athleta_logo from './Athleta_logo.webp';
import gap_logo from './gap_logo.webp';
import kohls_logo from './kohls-logo.png';
import gapfactory_logo from './gapfactory_logo.svg';
import gapca_logo from './gapca_logo.png';
import marshalls_logo from './marshalls_logo.svg';
import old_navy_logo from './old_navy_logo.png';
import old_navy_ca_logo from './old_navy_ca_logo.png';
import sierra_logo from './sierra_logo.png';
import belk_logo from './Belk_Logo.jpg';
import tjmaxx_logo from './tjmaxx_logo.png';
import bloomingdales_logo from './bloomingdales_logo.png';
import bananarepublic_logo from './bananarepublic_logo.png';
import bananarepublicca_logo from './bananarepublicca_logo.jpg';
import landsend_logo from './landsend_logo.svg';
import west_elm_logo from './west_elm_logo.png';
import eddiebauer_logo from './eddiebauer_logo.png';
import bedbathandbeyond_logo from './bedbathandbeyond_logo.jpeg';

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api';
export const CLIENT_ID_MAPPINGS = {
  bananarepublic: 23,
  bergdorfgoodman: 11,
  bloomingdales: 22,
  frontgate: 20,
  grainger: 21,
  horchow: 13,
  lastcall: 12,
  llbean: 10,
  neimanmarcus: 4,
  orvis: 2,
};

export const LOGOS ={
  athleta: athleta_logo,
  frontgate: frontgate_logo,
  backcountry: backcountry_logo,
  llbean: llbean_logo,
  grainger: grainger_logo,
  kohls: kohls_logo,
  gap: gap_logo,
  gapfactory: gapfactory_logo,
  gapca: gapca_logo,
  marshalls: marshalls_logo,
  oldnavy: old_navy_logo,
  oldnavyca: old_navy_ca_logo,
  sierra: sierra_logo,
  belk: belk_logo,
  tjmaxx: tjmaxx_logo,
  bloomingdales: bloomingdales_logo,
  bananarepublic: bananarepublic_logo,
  bananarepublicca: bananarepublicca_logo,
  landsend: landsend_logo,
  westelm: west_elm_logo,
  eddiebauer: eddiebauer_logo,
  bedbathandbeyond: bedbathandbeyond_logo,
}
