import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import CustomerJourneyEventCard from './CustomerJourneyEventCard';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

function ListEmailAddresses({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Customer Journeys';
  });

  const params = parseQuery(history);

  const url = `${BASE_URL}/list_customer_journey_email_addresses?${makeQuery(params)}`;

  const [email_addresses, loaded, errorLoading] = useFetch(url, [], authUser);
  function Display() {
    if (loaded) {
      return (
        <div>
          {email_addresses.map((data, index) => (
            <Button
              variant="contained"
              component={Link}
              to={`/customer_journeys/${data['email_address_normed']}?${makeQuery(params)}`}
            >
              {data['email_address_normed']}
            </Button>
          ))}
        </div>
      );
    } else if (errorLoading) {
      return <Typography color="error">Unable to load customer journey data.</Typography>;
    } else {
      return <CircularProgress />;
    }
  }

  return <Display />;
}

function OneEventCustomerJourney({ match, history, authUser }) {
  const params = parseQuery(history);

  const searchParams = new URLSearchParams(makeQuery(params));
  searchParams.set('email_address', match.params.email_address);
  searchParams.set('event_id', match.params.event_id);
  const url = `${BASE_URL}/get_customer_journey_one_event?${searchParams.toString()}`;
  const [result, dataLoaded, errorLoading] = useFetch(url, [], authUser);

  useEffect(() => {
    document.title = `Customer Journey ${match.params.email_address}`;
  });

  function Display() {
    if (dataLoaded) {
      return (
        <div>
          {result.map((data, index) => (
            <img key={index} src={data.thumbnail} alt={data.creative_title} />
          ))}
        </div>
      );
    } else if (errorLoading) {
      return <Typography color="error">Unable to load data for this customer.</Typography>;
    } else {
      return <CircularProgress />;
    }
  }

  return <Display />;
}

function AllEventsCustomerJourney({ match, history, authUser }) {
  const params = parseQuery(history);

  const searchParams = new URLSearchParams(makeQuery(params));
  searchParams.set('email_address', match.params.email_address);
  const url = `${BASE_URL}/get_customer_journey_all_events?${searchParams.toString()}`;
  const [result, dataLoaded, errorLoading] = useFetch(url, [], authUser);

  useEffect(() => {
    document.title = `Customer Journey ${match.params.email_address}`;
  });

  function Display() {
    if (dataLoaded) {
      return (
        <Grid container>
          {result.map((data, index) => (
            <Grid item xs={4} key={index}>
              <CustomerJourneyEventCard match={match} params={params} authUser={authUser} data={data} />
            </Grid>
          ))}
        </Grid>
      );
    } else if (errorLoading) {
      return <Typography color="error">Unable to load data for this customer.</Typography>;
    } else {
      return <CircularProgress />;
    }
  }

  return <Display />;
}

export default function CustomerJourneys({ match, authUser }) {
  return (
    <div>
      <Route
        path={`${match.path}/:email_address/:event_id`}
        render={(props) => <OneEventCustomerJourney {...props} authUser={authUser} />}
      />
      <Route
        exact
        path={`${match.path}/:email_address`}
        render={(props) => <AllEventsCustomerJourney {...props} authUser={authUser} />}
      />
      <Route exact path={match.path} render={(props) => <ListEmailAddresses {...props} authUser={authUser} />} />
    </div>
  );
}
