// @format
// TODO: This an EventTag are almost identical and could be refactored into a base component. Maybe Audience too?
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ExperimentSelector = ({ experiments, setExperiments, experimentChoices }) => {
  const handleChange = (experiment) => (event) => {
    setExperiments({ ...experiments, [experiment['id']]: event.target.checked });
  };

  if (experimentChoices.length === 0) {
    return null;
  } else {
    return (
      <div>
        <FormGroup row>
          {experimentChoices.map((experiment, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={experiments[experiment['id']] || false}
                  onChange={handleChange(experiment)}
                  value={experiment['id']}
                />
              }
              label={experiment['title']}
            />
          ))}
        </FormGroup>
      </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

export function ExperimentDisplay({ experimentChoices, experiments, alwaysShow }) {
  const classes = useStyles();
  const currentExperiments = experimentChoices.filter((x) => experiments[x.id]);
  return currentExperiments.length > 0 || alwaysShow ? (
    <div className={classes.display}>
      <Typography variant="h6">Experiments</Typography>
      {currentExperiments.map((experiment, index) => (
        <Chip key={index} label={experiment.title} className={classes.chip} />
      ))}
    </div>
  ) : null;
}

export function ExperimentPanel({ experiments, experimentChoices, handleChange }) {
  return (
    <React.Fragment>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <ExperimentDisplay experimentChoices={experimentChoices} experiments={experiments} alwaysShow />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ExperimentSelector
            experiments={experiments}
            setExperiments={handleChange('experiments')}
            experimentChoices={experimentChoices}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
}
