// @format
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import EmailResults from './EmailResults';
import moment from 'moment';

function AllEvents({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Summary';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/get_email_summary?${makeQuery(params)}`;
  const [summary_data , loaded, errorLoading] = useFetch(url, [], authUser);

  function Display() {
    if (loaded) {
      const last_updated = (summary_data.effect || summary_data.control || summary_data).last_updated;
      return (
        <div>
          {last_updated ? (
            <p>Last Updated: {moment(last_updated + '+00:00').format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          ) : null}
          <EmailResults
            data={summary_data}
            authUser={authUser}
            hideEffectiveness
            directTitle={
              params.primary
                ? 'Primary'
                : params.notPrimary
                ? 'Not Primary'
                : Object.keys(params.contentBlocks).length > 0
                ? 'Content Block Selection'
                : 'Primary'
            }
            allTitle="All"
          />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load event data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Display />
    </div>
  );
}

export default function Summary({ match, authUser }) {
  return (
    <div>
      <Route exact path={match.path} render={(props) => <AllEvents {...props} authUser={authUser} />} />
    </div>
  );
}
