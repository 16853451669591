import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, authUser, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? <Component {...props} {...rest} authUser={authUser} /> : <Redirect to="/signin" />
      }
    />
  );
}
