import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MouseIcon from '@material-ui/icons/Mouse';
import DraftsIcon from '@material-ui/icons/Drafts';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { makeQuery } from './QueryBuilder/QueryBuilder';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cardMedia: {
    maxHeight: '350px',
    objectFit: 'cover',
    objectPosition: '50% 0%',
    cursor: 'pointer',
    textAlign: 'center',
  },
  chip: {
    margin: theme.spacing(1),
  },
  cardContent: {
    height: '250px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export default function CustomerJourneyEventCard({ data, params, match, authUser }) {
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <Card className={classes.card} raised>
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={() => setLightboxOpen(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img src={data['thumbnail']} style={{ maxWidth: '100%' }} alt={data['creative_title']} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Link
        to={`/customer_journeys/${match.params.email_address}/${data.event_id}?${makeQuery(params)}`}
        className={classes.link}
      >
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle1">{data['event_title']}</Typography>
          <Typography gutterBottom color="textSecondary" variant="body2">
            {`${moment(data.send_datetime).format('ddd, MMM Do YYYY, h:mm a')}`}
          </Typography>
          <Typography gutterBottom variant="body2">
            {data['subject_line']}
          </Typography>
          <Divider variant="left" />
          <Typography gutterBottom variant="body2">
            {data['creative_title']}
          </Typography>
          <Typography gutterBottom variant="body2">
            Customer Effectiveness:{' '}
            {data.effectiveness ? data['effectiveness'].toLocaleString(undefined, { maximumFractionDigits: 2 }) : ''}
          </Typography>
          <Typography gutterBottom variant="body2">
            Overall Effectiveness:{' '}
            {data.overall_effectiveness
              ? data['overall_effectiveness'].toLocaleString(undefined, { maximumFractionDigits: 2 })
              : ''}
          </Typography>
          <div>
            {data['open_unique_count'] > 0 ? (
              <Chip label="Opened" icon={<DraftsIcon />} className={classes.chip} />
            ) : null}
            {data['click_unique_count'] > 0 ? (
              <Chip label="Clicked" icon={<MouseIcon />} className={classes.chip} />
            ) : null}
          </div>
        </CardContent>
        <CardMedia className={classes.cardMedia} component="img" image={data['thumbnail']} />
      </Link>
    </Card>
  );
}
