import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  positiveLift: {
    color: 'lime',
  },
  negativeLift: {
    color: 'red',
  },
  noLift: {},
}));

// https://stackoverflow.com/questions/16194730/seeking-a-statistical-javascript-function-to-return-p-value-from-a-z-score
function getZPercent(z, isAnalyticsUser) {
  //if z is greater than 6.5 standard deviations from the mean
  //the number of significant digits will be outside of a reasonable
  //range
  z = Math.abs(z);
  if (z > 6.5) return 1.0;

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);
  while (Math.abs(term) > loopStop) {
    term =
      (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) / (2 * k + 1) / Math.pow(2, k)) * Math.pow(z, k + 1)) / factK;
    sum += term;
    k++;
    factK *= k;
  }
  sum += 0.5;

  if (isAnalyticsUser) return 1 - 2 * (1 - sum);
  else return sum;
}

export default function MetricMaker({ effect_data, control_data, authUser, xs }) {
  const classes = useStyles();

  function Metric({
    title,
    tooltip,
    base,
    valComputer,
    varComputer,
    countComputer,
    varValComputer,
    hideLift,
    showTotalLift,
    totalFormater,
    reverseSign,
  }) {
    const Lift = () => {
      if (!control_data || !valComputer || hideLift) return null;
      const effectVal = valComputer(effect_data);
      const controlVal = valComputer(control_data);
      const lift = showTotalLift
        ? effectVal - (controlVal * countComputer(effect_data)) / countComputer(control_data)
        : effectVal / controlVal - 1;
      let zscore = lift === 0 ? 0 : lift > 0 ? 3 : -3;
      const computeVariance = varComputer && countComputer;
      if (computeVariance) {
        varValComputer = varValComputer || valComputer;
        zscore =
          (varValComputer(effect_data) - varValComputer(control_data)) /
          Math.sqrt(
            varComputer(effect_data) / countComputer(effect_data) +
              varComputer(control_data) / countComputer(control_data)
          );
      }
      const isAnalyticsUser = ['rob@coherentpath.com', 'greg@coherentpath.com', 'aaron.wu@coherentpath.com'].includes(authUser.email);
      const significanceCutoff = isAnalyticsUser ? 1.645 : 1.282;
      var className;
      var confidenceTooltip;
      if (computeVariance) {
        if (reverseSign)
          className =
            zscore > significanceCutoff
              ? classes.negativeLift
              : zscore < -significanceCutoff
              ? classes.positiveLift
              : classes.noLift;
        else
          className =
            zscore > significanceCutoff
              ? classes.positiveLift
              : zscore < -significanceCutoff
              ? classes.negativeLift
              : classes.noLift;
        confidenceTooltip = `Confidence of ${
          zscore >= 0 ? 'Positive' : 'Negative'
        } Difference vs. Control: ${getZPercent(zscore, isAnalyticsUser).toLocaleString(undefined, {
          style: 'percent',
        })}`;
        if (isAnalyticsUser)
          confidenceTooltip += ' (' + zscore.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ')';
      } else {
        className = classes.noLift;
        confidenceTooltip = 'Confidence not computed';
      }
      return (
        <Tooltip title={confidenceTooltip}>
          <span className={className}>
            {' '}
            ({lift >= 0 ? '+' : ''}
            {showTotalLift
              ? totalFormater(lift)
              : lift.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 })}
            )
          </span>
        </Tooltip>
      );
    };
    return (
      <Grid item xs={xs}>
        <ListItem dense disableGutters>
          <ListItemText
            primary={
              <Tooltip title={tooltip}>
                <Typography align="left">{title}</Typography>
              </Tooltip>
            }
            secondary={
              <span>
                <Tooltip title={tooltip}>
                  <span>{base}</span>
                </Tooltip>
                <Lift />
              </span>
            }
          />
        </ListItem>
      </Grid>
    );
  }

  return Metric;
}
