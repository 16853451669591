// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MultipleDatesPicker from '@randex/material-ui-multiple-dates-picker';

const useStyles = makeStyles((theme) => ({
  datepicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

const ExcludedDatesChips = ({ excluded_dates }) => {
  const classes = useStyles();
  return excluded_dates.map((date, idx) => (
    <Chip key={idx} className={classes.chip} label={moment(date).format('MMMM Do YYYY')} />
  ));
};

export function DateRangeDisplay({ start_date, end_date, excluded_dates }) {
  const classes = useStyles();
  return (
    <div className={classes.display}>
      <Typography variant="h6">Date Range</Typography>
      <Chip className={classes.chip} label={start_date.format('MMMM Do YYYY')} />
      <Typography variant="h6">to</Typography>
      <Chip className={classes.chip} label={end_date.format('MMMM Do YYYY')} />
      {excluded_dates.length > 0 ? (
        <React.Fragment>
          <Typography variant="h6">except:</Typography>
          <ExcludedDatesChips excluded_dates={excluded_dates} />
        </React.Fragment>
      ) : null}
    </div>
  );
}

export function DateRangePanel({ start_date, end_date, excluded_dates, handleChange }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const ExcludedDatesChips = () => {
    return excluded_dates.map((date, idx) => (
      <Chip key={idx} className={classes.chip} label={moment(date).format('MMMM Do YYYY')} />
    ));
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <DateRangeDisplay start_date={start_date} end_date={end_date} excluded_dates={excluded_dates} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.datepicker}>
            <DatePicker
              label="Start Date"
              value={start_date}
              onChange={handleChange('start_date')}
              autoOk
              maxDate={moment.min(moment(), end_date)}
              minDate={moment().subtract(365, 'days')}
              format="MMMM Do YYYY"
            />
          </div>
          <div className={classes.datepicker}>
            <DatePicker
              label="End Date"
              value={end_date}
              onChange={handleChange('end_date')}
              autoOk
              minDate={moment.max(moment().subtract(3, 'years'), start_date)}
              maxDate={moment()}
              format="MMMM Do YYYY"
            />
          </div>
          <div className={classes.datepicker}>
            <Button onClick={() => setOpen(!open)}>
              Excluded Dates: <ExcludedDatesChips excluded_dates={excluded_dates} />
            </Button>
            <MultipleDatesPicker
              open={open}
              selectedDates={excluded_dates}
              onCancel={() => setOpen(false)}
              onSubmit={(newDate) => {
                let sorted = newDate
                  .map((x) => moment(x))
                  .sort((a, b) => a.diff(b))
                  .map((x) => x.toDate());
                handleChange('excluded_dates')(sorted);
                setOpen(false);
              }}
              selectedDatesTitle="Excluded Dates"
            />
          </div>
        </MuiPickersUtilsProvider>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
