import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import UnattributedResults from './UnattributedResults';

export default function Transactions({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Transactions';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/get_unattributed?${makeQuery(params)}`;

  const [{ summary }, loaded, errorLoading] = useFetch(url, [], authUser);
  function Display() {
    if (loaded) {
      return (
        <div>
          <UnattributedResults data={summary} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load transactions data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} hideEventTags hideContentBlock authUser={authUser} />
      <Display />
    </div>
  );
}
