// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EmailResults from './EmailResults';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  cardContent: {
    width: '100%',
  },
}));

function Title({ day_obj }) {
  return (
    <div>
      <Typography gutterBottom variant="h5">
        {day_obj.day}
      </Typography>
    </div>
  );
}

function Actions({ day_obj, hideHistoryButton }) {
  let params = new URLSearchParams(window.location.search);

  return (
    <CardActions>
      {hideHistoryButton ? null : (
        <Button color="secondary" component={Link} to={`/days/${day_obj.day}?${params.toString()}`}>
          Details
        </Button>
      )}
    </CardActions>
  );
}

export default function DayCard({ day_data, hideHistoryButton, authUser, hideEffectiveness }) {
  const classes = useStyles();
  const day_obj = day_data.control || day_data.effect || day_data;
  return (
    <Card className={classes.card} raised>
      <CardContent className={classes.cardContent}>
        <Title day_obj={day_obj} />
        <EmailResults
          data={day_data}
          authUser={authUser}
          hideEffectiveness={hideEffectiveness}
          allTitle={'All'}
          directTitle={'Primary'}
        />
        <Actions day_obj={day_obj} hideHistoryButton={hideHistoryButton} />
      </CardContent>
    </Card>
  );
}
