// @format
import React from 'react';
import { AudienceSelector } from './Audience';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

export function ComparisonDisplay({ selectedEffect, selectedControl, alwaysShow }) {
  const classes = useStyles();
  const comparisonExists = selectedEffect.length > 0 || selectedControl.length > 0;
  return comparisonExists || alwaysShow ? (
    <div className={classes.display}>
      <Typography variant="h6">Comparison</Typography>
      {!comparisonExists ? null : (
        <React.Fragment>
          {(selectedEffect.length > 0 ? selectedEffect : [{ title: 'Everyone' }]).map((audience, index) => (
            <Chip key={index} label={audience.title} className={classes.chip} />
          ))}
          <Typography variant="h6">vs.</Typography>
          {(selectedControl.length > 0 ? selectedControl : [{ title: 'Everyone' }]).map((audience, index) => (
            <Chip key={index} label={audience.title} className={classes.chip} />
          ))}
        </React.Fragment>
      )}
    </div>
  ) : null;
}

export function ComparisonPanel({ params, onParamChange, audienceChoices, selectedEffect, selectedControl }) {
  const handleChange = (key) => (newParam) => {
    onParamChange({ ...params, [key]: newParam });
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <ComparisonDisplay selectedEffect={selectedEffect} selectedControl={selectedControl} alwaysShow />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <Typography variant="h6">Test</Typography>
          <AudienceSelector
            audiences={params.effect}
            setAudiences={handleChange('effect')}
            audienceChoices={audienceChoices}
          />
          <Typography variant="h6">Control</Typography>
          <AudienceSelector
            audiences={params.control}
            setAudiences={handleChange('control')}
            audienceChoices={audienceChoices}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
