// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import MetricMaker from './MetricMaker';

const useStyles = makeStyles((theme) => ({
  single: {},
  primaryOrAllButtonGroup: {
    marginTop: '6px',
  },
}));

function SendBreakdown({ effect_data }) {
  if (effect_data['sends'] > 0) {
    return (
      <ul>
        <li>
          Preview Sends {effect_data['preview_sends'].toLocaleString()} (
          {(effect_data['preview_sends'] / effect_data['sends']).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          )
        </li>
        <li>
          Forced Sends {effect_data['forced_sends'].toLocaleString()} (
          {(effect_data['forced_sends'] / effect_data['sends']).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          )
        </li>
        <li>
          Fallback Sends {effect_data['fallback_sends'].toLocaleString()} (
          {(effect_data['fallback_sends'] / effect_data['sends']).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          )
        </li>
        <li>
          Optimized Sends {effect_data['optimized_sends'].toLocaleString()} (
          {(effect_data['optimized_sends'] / effect_data['sends']).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          )
        </li>
      </ul>
    );
  } else return null;
}

function Single({
  effect_data,
  control_data,
  authUser,
  hideEffectiveness,
  objectTitle,
  directOrAll,
  leftOrRight,
  setLeftOrRight,
  leftTitle,
  rightTitle,
}) {
  const classes = useStyles();
  const Metric = MetricMaker({ effect_data, control_data, authUser, xs: 4 });
  objectTitle = objectTitle || 'the primary content block of an email';

  if (!effect_data) return null;

  return (
    <div className={classes.single}>
      <Grid spacing={2} container>
        <Metric
          title="Sends"
          tooltip={<SendBreakdown effect_data={effect_data} />}
          base={(effect_data.sends || 0).toLocaleString()}
          hideLift
        />
        {effect_data.events ? (
          <Metric
            title="Events"
            tooltip="Number of events included in this summary."
            base={effect_data.events.toLocaleString()}
            hideLift
          />
        ) : null}
        {effect_data.most_recent_send ? (
          <Metric
            title="Last Send"
            tooltip="Date of the most recent send included in this summary."
            base={effect_data.most_recent_send}
            hideLift
          />
        ) : null}
      </Grid>
      <Divider />
      {effect_data.creatives ? (
        <React.Fragment>
          <Grid spacing={2} container>
            <Metric
              title="Creatives"
              tooltip="Number of distinct creatives sent in the primary slot of email."
              base={effect_data.creatives.toLocaleString()}
              hideLift
            />
            {effect_data.launched_creatives ? (
              <Metric
                title="Launched Creatives"
                tooltip="Number of distinct creatives that were newly launched in the primary slot of email."
                base={effect_data.launched_creatives.toLocaleString()}
                hideLift
              />
            ) : null}
            {effect_data.launched_creatives && effect_data.events ? (
              <Metric
                title="Creative Generation Rate"
                tooltip="The number of primary zone creatives created per event launched. Defined as Launched Creatives / Events."
                base={(effect_data.launched_creatives / effect_data.events).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                hideLift
              />
            ) : null}
          </Grid>
          <Divider />
        </React.Fragment>
      ) : null}
      <Grid spacing={2} container>
        <Metric
          title="Resend Rate"
          tooltip="The % of sends containing a creative that the subscriber has been sent at least once before."
          base={(
            (effect_data.use_count_2 + effect_data.use_count_3 + effect_data.use_count_gt3) /
            effect_data.sends
          ).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
          valComputer={(x) => (x.use_count_2 + x.use_count_3 + x.use_count_gt3) / x.sends}
          hideLift
        />
        <Metric
          title="Resend >1 Rate"
          tooltip="The % of sends containing a creative that the subscriber has been sent at least twice before."
          base={((effect_data.use_count_3 + effect_data.use_count_gt3) / effect_data.sends).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => (x.use_count_3 + x.use_count_gt3) / x.sends}
          hideLift
        />
        <Metric
          title="Resend >2 Rate"
          tooltip="The % of sends containing a creative that the subscriber has been sent at least three times before."
          base={(effect_data.use_count_gt3 / effect_data.sends).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.use_count_gt3 / x.sends}
          hideLift
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Opens"
          tooltip='Number of times a subscriber opened an email. Note that these are "unique opens", so multiple opens of the same email are counted as 1 open.'
          base={(effect_data.opens || 0).toLocaleString()}
          valComputer={(x) => x.opens}
          varComputer={(x) => (x.opens / x.sends) * (1 - x.opens / x.sends)}
          varValComputer={(x) => x.opens / x.sends}
          countComputer={(x) => x.sends}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Open Rate"
          tooltip="The ratio of opens to sends as a percentage."
          base={(effect_data.opens / effect_data.sends).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.opens / x.sends}
          varComputer={(x) => (x.opens / x.sends) * (1 - x.opens / x.sends)}
          countComputer={(x) => x.sends}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Unsubscribes"
          tooltip="Number of times a subscriber unsubscribed an email."
          base={(effect_data.unsubs || 0).toLocaleString()}
          valComputer={(x) => x.unsubs}
          varComputer={(x) => (x.unsubs / x.sends) * (1 - x.unsubs / x.sends)}
          varValComputer={(x) => x.unsubs / x.sends}
          countComputer={(x) => x.sends}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
          reverseSign
        />
        <Metric
          title="Unsubscribe Rate"
          tooltip="The ratio of unsubscribes to sends as a percentage."
          base={(effect_data.unsubs / effect_data.sends).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.unsubs / x.sends}
          varComputer={(x) => (x.unsubs / x.sends) * (1 - x.unsubs / x.sends)}
          countComputer={(x) => x.sends}
          reverseSign
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Grid item xs={12}>
          {setLeftOrRight ? (
            <ContentBlockActionsSwitch
              leftOrRight={leftOrRight}
              setLeftOrRight={setLeftOrRight}
              leftTitle={leftTitle}
              rightTitle={rightTitle}
            />
          ) : null}
        </Grid>
      </Grid>
      {directOrAll === 'all' ? (
        <div>
          <Grid spacing={2} container>
            <Metric
              title="Clicks"
              tooltip='Number of times a subscriber clicked anywhere on an email. Note that these are "unique clicks", so multiple clicks of the same email address are counted as 1 click.'
              base={(effect_data.clicks || 0).toLocaleString()}
              valComputer={(x) => x.clicks}
              varComputer={(x) => (x.clicks / x.sends) * (1 - x.clicks / x.sends)}
              varValComputer={(x) => x.clicks / x.sends}
              countComputer={(x) => x.sends}
              totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              showTotalLift
            />
            <Metric
              title="Click Rate"
              tooltip="The ratio of clicks to sends as a percentage."
              base={(effect_data.clicks / effect_data.sends).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
              })}
              valComputer={(x) => x.clicks / x.sends}
              varComputer={(x) => (x.clicks / x.sends) * (1 - x.clicks / x.sends)}
              countComputer={(x) => x.sends}
            />
            {!hideEffectiveness ? (
              <Metric
                title="Click Effectiveness"
                tooltip="The ratio of the number of clicks to the number of expected clicks based on the sent subscribers past click behavior."
                base={(effect_data.clicks / effect_data.predilection).toLocaleString()}
                valComputer={(x) => x.clicks / x.predilection}
                varValComputer={(x) => x.clicks / x.sends}
                varComputer={(x) => (x.clicks / x.sends) * (1 - x.clicks / x.sends)}
                countComputer={(x) => x.sends}
              />
            ) : null}
          </Grid>
          <Divider />
          <Grid spacing={2} container>
            <Metric
              title="Conversions"
              tooltip="Number of times a subscriber made a purchase after clicking anywhere on an email."
              base={(effect_data.conversions || 0).toLocaleString()}
              valComputer={(x) => x.conversions}
              varComputer={(x) => (x.conversions / x.sends) * (1 - x.conversions / x.sends)}
              varValComputer={(x) => x.conversions / x.sends}
              countComputer={(x) => x.sends}
              totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              showTotalLift
            />
            <Metric
              title="Conversion Rate"
              tooltip="The ratio of conversions to sends as a percentage."
              base={(effect_data.conversions / effect_data.sends).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 4,
              })}
              valComputer={(x) => x.conversions / x.sends}
              varComputer={(x) => (x.conversions / x.sends) * (1 - x.conversions / x.sends)}
              countComputer={(x) => x.sends}
            />
            <Metric
              title="Click To Conversion Rate"
              tooltip="The ratio of conversions to clicks as a percentage."
              base={(effect_data.conversions / effect_data.clicks).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
              })}
              valComputer={(x) => x.conversions / x.clicks}
              varComputer={(x) => (x.conversions / x.clicks) * (1 - x.conversions / x.clicks)}
              countComputer={(x) => x.clicks}
            />
          </Grid>
          <Divider />
          <Grid spacing={2} container>
            <Grid item xs={4}>
              <Metric
                title="Revenue"
                tooltip="The total amount spent by subscribers after clicking anywhere on an email."
                base={(effect_data.conversion_amount || 0).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}
                valComputer={(x) => x.conversion_amount || 0}
                totalFormater={(x) => x.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
                varComputer={(x) => x.amount_per_send_var}
                varValComputer={(x) => x.conversion_amount / x.sends}
                countComputer={(x) => x.sends}
                showTotalLift
              />
            </Grid>
            <Grid item xs={4}>
              <Metric
                title="Revenue Per Send"
                tooltip="The total revenue divided the number of sends."
                base={(effect_data.conversion_amount / effect_data.sends).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 4,
                })}
                valComputer={(x) => x.conversion_amount / x.sends}
                varComputer={(x) => x.amount_per_send_var}
                countComputer={(x) => x.sends}
              />
            </Grid>
            <Grid item xs={4}>
              <Metric
                title="Revenue Per Conversion"
                tooltip="The total revenue divideded by the number of conversions. Sometimes this value is called the Average Order Value (AOV) or Basket Size"
                base={(effect_data.conversion_amount / effect_data.conversions).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}
                valComputer={(x) => x.conversion_amount / x.conversions}
                varComputer={(x) => x.amount_per_conversion_var}
                countComputer={(x) => x.conversions}
              />
            </Grid>
          </Grid>
          <Divider />
        </div>
      ) : (
        <div>
          <Grid spacing={2} container>
            <Metric
              title="Clicks"
              tooltip={`Number of times a subscriber clicked on ${objectTitle}. Note that these are "unique clicks", so multiple clicks from the same email address on the same email are counted as 1 click.`}
              base={(effect_data.cb_clicks || 0).toLocaleString()}
              valComputer={(x) => x.cb_clicks}
              varComputer={(x) => (x.cb_clicks / x.sends) * (1 - x.cb_clicks / x.sends)}
              varValComputer={(x) => x.cb_clicks / x.sends}
              countComputer={(x) => x.sends}
              totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              showTotalLift
            />
            <Metric
              title="Click Rate"
              tooltip="The ratio of clicks to sends as a percentage."
              base={(effect_data.cb_clicks / effect_data.sends).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
              })}
              valComputer={(x) => x.cb_clicks / x.sends}
              varComputer={(x) => (x.cb_clicks / x.sends) * (1 - x.cb_clicks / x.sends)}
              countComputer={(x) => x.sends}
            />
          </Grid>
          <Divider />
          <Grid spacing={2} container>
            <Metric
              title="Conversions"
              tooltip={`Number of times a subscriber made a purchase after clicking on ${objectTitle}.`}
              base={(effect_data.rd_conversions || 0).toLocaleString()}
              valComputer={(x) => x.rd_conversions}
              varComputer={(x) => (x.rd_conversions / x.sends) * (1 - x.rd_conversions / x.sends)}
              varValComputer={(x) => x.rd_conversions / x.sends}
              countComputer={(x) => x.sends}
              totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              showTotalLift
            />
            <Metric
              title="Conversion Rate"
              tooltip="The ratio of conversions to sends as a percentage."
              base={(effect_data.rd_conversions / effect_data.sends).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 4,
              })}
              valComputer={(x) => x.rd_conversions / x.sends}
              varComputer={(x) => (x.rd_conversions / x.sends) * (1 - x.rd_conversions / x.sends)}
              countComputer={(x) => x.sends}
            />
            <Metric
              title="Click To Conversion Rate"
              tooltip="The ratio of conversions to clicks as a percentage."
              base={(effect_data.rd_conversions / effect_data.cb_clicks).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2,
              })}
              valComputer={(x) => x.rd_conversions / x.cb_clicks}
              varComputer={(x) => (x.rd_conversions / x.cb_clicks) * (1 - x.rd_conversions / x.cb_clicks)}
              countComputer={(x) => x.cb_clicks}
            />
          </Grid>
          <Divider />
          <Grid spacing={2} container>
            <Grid item xs={4}>
              <Metric
                title="Revenue"
                tooltip={`The total amount spent by subscribers after clicking on ${objectTitle}.`}
                base={(effect_data.rd_conversion_amount || 0).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}
                valComputer={(x) => x.rd_conversion_amount || 0}
                totalFormater={(x) => x.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
                varComputer={(x) => x.rd_amount_per_send_var}
                varValComputer={(x) => x.rd_conversion_amount / x.sends}
                countComputer={(x) => x.sends}
                showTotalLift
              />
            </Grid>
            <Grid item xs={4}>
              <Metric
                title="Revenue Per Send"
                tooltip="The total revenue divided the number of sends."
                base={(effect_data.rd_conversion_amount / effect_data.sends).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 4,
                })}
                valComputer={(x) => x.rd_conversion_amount / x.sends}
                varComputer={(x) => x.rd_amount_per_send_var}
                countComputer={(x) => x.sends}
              />
            </Grid>
            <Grid item xs={4}>
              <Metric
                title="Revenue Per Conversion"
                tooltip="The total revenue divideded by the number of conversions. Sometimes this value is called the Average Order Value (AOV) or Basket Size"
                base={(effect_data.rd_conversion_amount / effect_data.rd_conversions).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}
                valComputer={(x) => x.rd_conversion_amount / x.rd_conversions}
                varComputer={(x) => x.rd_amount_per_conversion_var}
                countComputer={(x) => x.rd_conversions}
              />
            </Grid>
          </Grid>
          <Divider />
        </div>
      )}
    </div>
  );
}

function ContentBlockActionsSwitch({ leftOrRight, setLeftOrRight, leftTitle, rightTitle }) {
  const classes = useStyles();
  return (
    <div className={classes.primaryOrAllButtonGroup}>
      <ButtonGroup variant="contained" className={classes.primaryOrAllButtonGroup}>
        <Button color={leftOrRight === 'left' ? 'primary' : null} onClick={() => setLeftOrRight('left')}>
          {leftTitle}
        </Button>
        <Button color={leftOrRight === 'right' ? 'primary' : null} onClick={() => setLeftOrRight('right')}>
          {rightTitle}
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default function EmailResults({
  data,
  authUser,
  hideEffectiveness,
  notPrimaryClicks,
  directTitle,
  allTitle,
  objectTitle,
}) {
  const classes = useStyles();
  const [contentBlockSwitchLeftRight, setContentBlockSwitchLeftRight] = React.useState(
    notPrimaryClicks ? 'right' : 'left'
  );
  const directOrAll = contentBlockSwitchLeftRight === 'left' ? 'all' : 'direct';

  return (
    <div>
      {data.control || data.effect ? (
        <Grid container spacing={8} className={classes.root}>
          <Grid item lg={6} xs={12}>
            <Typography variant="h6">Test</Typography>
            <Single
              effect_data={data['effect']}
              control_data={data['control']}
              authUser={authUser}
              hideEffectiveness={hideEffectiveness}
              notPrimaryClicks={notPrimaryClicks}
              directOrAll={directOrAll}
              objectTitle={objectTitle}
              leftOrRight={contentBlockSwitchLeftRight}
              setLeftOrRight={setContentBlockSwitchLeftRight}
              leftTitle={allTitle}
              rightTitle={directTitle}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant="h6">Control</Typography>
            <Single
              effect_data={data['control']}
              authUser={authUser}
              hideEffectiveness={hideEffectiveness}
              notPrimaryClicks={notPrimaryClicks}
              directOrAll={directOrAll}
              objectTitle={objectTitle}
              leftOrRight={contentBlockSwitchLeftRight}
              setLeftOrRight={setContentBlockSwitchLeftRight}
              leftTitle={allTitle}
              rightTitle={directTitle}
            />
          </Grid>
        </Grid>
      ) : (
        <Single
          effect_data={data}
          authUser={authUser}
          hideEffectiveness={hideEffectiveness}
          notPrimaryClicks={notPrimaryClicks}
          directOrAll={directOrAll}
          objectTitle={objectTitle}
          leftOrRight={contentBlockSwitchLeftRight}
          setLeftOrRight={setContentBlockSwitchLeftRight}
          leftTitle={allTitle}
          rightTitle={directTitle}
        />
      )}
    </div>
  );
}
