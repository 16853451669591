// @format
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

export function AudienceDisplay({ selectedAudiences, alwaysShow }) {
  const classes = useStyles();
  return selectedAudiences.length > 0 || alwaysShow ? (
    <div className={classes.display}>
      <Typography variant="h6">Audience</Typography>
      {selectedAudiences.map((audience, index) => (
        <Chip key={index} label={audience.title} className={classes.chip} />
      ))}
    </div>
  ) : null;
}

export function AudiencePanel({ selectedAudiences, audience, handleChange, audienceChoices }) {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <AudienceDisplay selectedAudiences={selectedAudiences} alwaysShow />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <AudienceSelector
          audiences={audience}
          setAudiences={handleChange('audience')}
          audienceChoices={audienceChoices}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export function AudienceSelector({ audiences, setAudiences, audienceChoices }) {
  const handleChange = (audience) => (event) => {
    setAudiences({ ...audiences, [audience['id']]: event.target.checked });
  };

  if (audienceChoices.length === 0) {
    return null;
  } else {
    return (
      <div>
        <FormGroup row>
          {audienceChoices.map((audience, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={audiences[audience['id']] || false}
                  onChange={handleChange(audience)}
                  value={audience['id']}
                />
              }
              label={audience['title']}
            />
          ))}
        </FormGroup>
      </div>
    );
  }
}
