import firebase from 'firebase/app';
import 'firebase/auth';
const config = {
  apiKey: 'AIzaSyBCyGKdeg7USkAk31sRVyM28U-0AVr9lT8',
  authDomain: 'probable-symbol-234620.firebaseapp.com',
  databaseURL: 'https://probable-symbol-234620.firebaseio.com',
  projectId: 'probable-symbol-234620',
  storageBucket: 'probable-symbol-234620.appspot.com',
  messagingSenderId: '1021302109312',
  appId: '1:1021302109312:web:e2ada6aec3a0fb24122265',
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
  }

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);
}

export default Firebase;
