// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function ExportFormatDialog({ open, onFinish, onCancel, params, authUser, table_type }) {
  const classes = useStyles();
  const formatChoices = useFetch(
    `${BASE_URL}/list_export_formats?client_id=${params.client_id}&environment=${params.environment}&table_type=${table_type}`,
    [],
    authUser
  )[0];

  const handleChoiceSelection = (event, selection) => {
    onFinish(selection);
  };

  return (
    <Dialog open={open} onClose={() => onCancel()} className={classes.root}>
      <DialogTitle id="simple-dialog-title">Select an Export Format</DialogTitle>
      <List>
        {formatChoices.map((choice, index) => (
          <ListItem button key={choice} onClick={(event) => handleChoiceSelection(event, choice)}>
            {choice}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
