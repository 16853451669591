// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LOGOS } from './Constants.js';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import TodayIcon from '@material-ui/icons/Today';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CPLogo from './CoherentPathLogo.png';

const drawerWidth = 225;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    padding: theme.spacing(6, 0, 3),
  },
}));

export default function LeftNav({ history }) {
  const classes = useStyles();
  const [params, setParams] = React.useState(new URLSearchParams(history.location.search));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  history.listen((location, action) => {
    setParams(new URLSearchParams(location.search));
  });
  if (!params.get('client_id')) {
    return null;
  }
  const environment = params.get('environment') || 'reporting_cache';
  const drawer = (
    <div>
      <Link to={`/?environment=${environment}`}>
        <Container>
          <img src={CPLogo} alt="Coherent Path" width="136" />
        </Container>
      </Link>
      <Link to={`/?environment=${environment}`}>
        <Container>
          <img src={LOGOS[params.get('client_id')]} alt="Coherent Path" width="136" />
        </Container>
      </Link>
      <ListItem button component={Link} to={`/events?${params.toString()}`}>
        <ListItemIcon>
          <DateRangeOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItem>
      <ListItem button component={Link} to={`/creatives?${params.toString()}`}>
        <ListItemIcon>
          <PhotoLibraryOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Creatives" />
      </ListItem>
      <ListItem button component={Link} to={`/days?${params.toString()}`}>
        <ListItemIcon>
          <TodayIcon />
        </ListItemIcon>
        <ListItemText primary="Days" />
      </ListItem>
      <ListItem button component={Link} to={`/transactions?${params.toString()}`}>
        <ListItemIcon>
          <ShoppingCart />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </ListItem>
      <ListItem button component={Link} to={`/category_exposure?${params.toString()}`}>
        <ListItemIcon>
          <ShoppingBasketIcon />
        </ListItemIcon>
        <ListItemText primary="Category Exposure" />
      </ListItem>
      <ListItem button component={Link} to={`/tag_exposure?${params.toString()}`}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Tag Exposure" />
      </ListItem>
      <ListItem button component={Link} to={`/customer_journeys?${params.toString()}`}>
        <ListItemIcon>
          <DirectionsRunIcon />
        </ListItemIcon>
        <ListItemText primary="Customer Email Diets" />
      </ListItem>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
