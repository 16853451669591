import React from 'react';
import CreativeCard from './CreativeCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { List, WindowScroller, AutoSizer } from 'react-virtualized';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import 'react-virtualized/styles.css';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import debounce from 'debounce';

const MemoizedList = React.memo(function ({ creative_list_idx, query, creative_data, params, authUser }) {
  const originalData = creative_data.control ? creative_data : { total: creative_data };
  var dataToShow = {};
  Object.keys(originalData).map((creative_list_idx) => {
    dataToShow[creative_list_idx] = originalData[creative_list_idx].filter(
      (x) =>
        !query ||
        (x.title || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.short_uuid || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.tags || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.id || '').toString().startsWith(query)
    );
    return null;
  });
  const getRow = (creative_list_idx) => ({ index, style }) => (
    <div style={style} key={index}>
      <CreativeCard
        key={index}
        creative_data={dataToShow[creative_list_idx][index]}
        params={params}
        authUser={authUser}
      />
    </div>
  );

  return (
    <div>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                rowCount={dataToShow[creative_list_idx].length}
                rowRenderer={getRow(creative_list_idx)}
                rowHeight={943}
                width={width}
                scrollTop={scrollTop}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </div>
  );
});

export default function CreativeList({ creative_data, params, authUser }) {
  const useStyles = makeStyles((theme) => ({
    searchField: {
      width: '100%',
    },
  }));

  // searchString = what was typed
  // query = what we search
  // They equal each other after a debounce period so we don't have to keep rendering the list
  const [searchString, setSearchString] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [showControlEffect, setShowControlEffect] = React.useState('total');

  const handleSearch = (event) => {
    const newValue = event.target.value;
    setSearchString(event.target.value);
    debounce(() => setQuery(newValue), 500)();
  };

  const classes = useStyles();

  return (
    <div>
      <TextField
        className={classes.searchField}
        placeholder="Search…"
        onChange={handleSearch}
        value={searchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {creative_data.control ? (
        <Tabs value={showControlEffect} onChange={(event, newValue) => setShowControlEffect(newValue)} centered>
          <Tab label="All" value="total" />
          <Tab label="Test" value="effect" />
          <Tab label="Control" value="control" />
        </Tabs>
      ) : null}
      <MemoizedList
        creative_list_idx={showControlEffect}
        creative_data={creative_data}
        query={query}
        params={params}
        authUser={authUser}
      />
    </div>
  );
}
