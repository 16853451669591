// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import EmailResults from './EmailResults';
import LaunchIcon from '@material-ui/icons/Launch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '24px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '915px',
  },
  cardMedia: {
    maxWidth: '220px',
    maxHeight: '800px',
    objectFit: 'contain',
    margin: theme.spacing(3, 3),
    cursor: 'pointer',
  },
  titleGrid: {
    overflow: 'auto',
    maxHeight: '210px',
  },
  cardContent: {},
  chartContainer: {
    minWidth: '200px',
    margin: theme.spacing(0, 2),
  },
  chart: {
    height: '350px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  chartBar: {
    width: '30px',
  },
  chartBarContainer: {
    height: '100%',
  },
  chartXAxisTitleLeft: {
    float: 'left',
    maxWidth: '100px',
  },
  chartXAxisTitleRight: {
    float: 'right',
    maxWidth: '100px',
  },
}));

function Chart({ creative_data }) {
  const max = Math.max(
    creative_data.really_hate,
    creative_data.hate,
    creative_data.neutral,
    creative_data.like,
    creative_data.really_like,
    creative_data.really_hate_sent,
    creative_data.hate_sent,
    creative_data.neutral_sent,
    creative_data.like_sent,
    creative_data.really_like_sent
  );

  const format = (value) => (value || 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 1 });

  const tooltipMessage = (percent, percent_sent, message) => (
    <Typography variant="subtitle2">
      The model predicts that {format(percent)} of all email addresses and {format(percent_sent)} of the email addresses
      sent this creative will spend <strong>{message}</strong> when we send this creative compared to an average
      creative.
    </Typography>
  );

  const ChartBar = ({ data, data_sent, color, message }) => (
    <Tooltip title={tooltipMessage(data, data_sent, message)}>
      <div className={classes.chartBarContainer}>
        <div style={{ height: format(1 - data / max) }} className={classes.chartBar}></div>
        <div style={{ backgroundColor: color, height: format(data / max) }} className={classes.chartBar}></div>
        <div
          style={{ position: 'relative', top: '-350px', height: format(1 - data_sent / max) }}
          className={classes.chartBar}
        ></div>
        <div
          style={{
            position: 'relative',
            top: '-350px',
            backgroundColor: 'white',
            height: format(data_sent / max),
            width: '10px',
            left: '10px',
          }}
          className={classes.chartBar}
        ></div>
      </div>
    </Tooltip>
  );

  const classes = useStyles();
  return (
    <div className={classes.chartContainer}>
      <div className={classes.chart}>
        <ChartBar
          data_sent={creative_data.really_hate_sent}
          data={creative_data.really_hate}
          color="#cf597e"
          message="much less"
        />
        <ChartBar data_sent={creative_data.hate_sent} data={creative_data.hate} color="#e88471" message="a bit less" />
        <ChartBar
          data_sent={creative_data.neutral_sent}
          data={creative_data.neutral}
          color="#e9e29c"
          message="about the same amount of"
        />
        <ChartBar data_sent={creative_data.like_sent} data={creative_data.like} color="#39b185" message="a bit more" />
        <ChartBar
          data_sent={creative_data.really_like_sent}
          data={creative_data.really_like}
          color="#009392"
          message="a lot more"
        />
      </div>
      <div className={classes.chartXAxisTitles}>
        <Typography gutterBottom variant="subtitle2" className={classes.chartXAxisTitleLeft} color="textSecondary">
          Customers Disinterested in This Creative
        </Typography>
        <Typography
          gutterBottom
          variant="subtitle2"
          className={classes.chartXAxisTitleRight}
          color="textSecondary"
          align="right"
        >
          Customers Interested in This Creative
        </Typography>
      </div>
    </div>
  );
}

function DetailsView({ creative_data }) {
  const classes = useStyles();
  return (
    <Grid spacing={0} container className={classes.titleGrid}>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Short UUID: ${creative_data['short_uuid']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`ID: ${creative_data['id']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Launch Date: ${creative_data['launch_date']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Expiration Date: ${creative_data['expiration_date']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Tags: ${creative_data['tags']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Product Collections: ${creative_data['product_collections']}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle2">
          {`Subject Lines: ${creative_data['subject_lines']}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function CreativeCard({ creative_data, hideHistoryButton, params, authUser }) {
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <Card className={classes.card} raised>
      <CardMedia
        className={classes.cardMedia}
        component="img"
        image={creative_data['thumbnail']}
        onClick={() => setLightboxOpen(true)}
      />
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={() => setLightboxOpen(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img src={creative_data['thumbnail']} style={{ maxWidth: '100%' }} alt={creative_data['title']} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5">
          {creative_data['title']}
        </Typography>
        <Tooltip title={<DetailsView creative_data={creative_data} />} interactive>
          <Button color="secondary">Details</Button>
        </Tooltip>
        <h3>Effectiveness: {creative_data.effectiveness ? creative_data.effectiveness.toLocaleString() : ''}</h3>
        <EmailResults
          data={creative_data}
          authUser={authUser}
          notPrimaryClicks
          allTitle="Emails containing this creative"
          directTitle="this creative"
          objectTitle="this creative"
        />

        <CardActions>
          <Button
            target="_blank"
            color="secondary"
            href={`https://app.coherentpath.com/creative/${creative_data['short_uuid']}`}
          >
            <LaunchIcon />
            Content Library
          </Button>
          {hideHistoryButton ? null : (
            <Button color="secondary" component={Link} to={`/creatives/${creative_data['id']}?${params}`}>
              History
            </Button>
          )}
        </CardActions>
      </CardContent>
      <Chart creative_data={creative_data} />
    </Card>
  );
}
