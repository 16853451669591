//@format
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CreativeCard from './CreativeCard';
import EventCard from './EventCard';
import useFetch from './useFetch';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import { BASE_URL } from './Constants';
import EmailResults from './EmailResults';
import CreativeList from './CreativeList';
import ExportFormatDialog from './ExportFormatDialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';

function AllCreatives({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Creatives';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/list_creatives?${makeQuery(params)}`;

  const [{ creative_data, summary_data }, loaded, errorLoading] = useFetch(url, [], authUser);

  const [exportFormatOpen, setExportFormatOpen] = React.useState(false);
  const doExport = (export_format) => {
    setExportFormatOpen(false);
    window.location.href = `${BASE_URL}/export_list_creatives?${makeQuery(
      params
    )}&r=${Math.random()}&export_format=${export_format}`;
  };

  function Display() {
    if (loaded) {
      const last_updated = (summary_data.effect || summary_data.control || summary_data).last_updated;
      return (
        <div>
          <ExportFormatDialog
            open={exportFormatOpen}
            params={params}
            authUser={authUser}
            table_type="creative"
            onCancel={() => setExportFormatOpen(false)}
            onFinish={doExport}
          />
          <Button variant="contained" onClick={() => setExportFormatOpen(true)}>
            Export
          </Button>
          {last_updated ? (
            <p>Last Updated: {moment(last_updated + '+00:00').format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          ) : null}
          <EmailResults
            data={summary_data}
            authUser={authUser}
            hideEffectiveness
            directTitle={
              params.primary
                ? 'Primary'
                : params.notPrimary
                ? 'Not Primary'
                : Object.keys(params.contentBlocks).length > 0
                ? 'Content Block Selection'
                : 'Primary'
            }
            allTitle="All"
          />
          <CreativeList creative_data={creative_data} params={makeQuery(params)} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load creative data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Display />
    </div>
  );
}

function OneCreative({ match, history, authUser }) {
  const [showControlEffect, setShowControlEffect] = React.useState('total');
  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const searchParams = new URLSearchParams(makeQuery(params));
  searchParams.set('id', match.params.id);
  const url = `${BASE_URL}/get_creative?${searchParams.toString()}`;
  const [{ event_data, creative_data }, loaded, errorLoading] = useFetch(url, [], authUser);

  const creativeDataToShow = creative_data && creative_data.control ? creative_data[showControlEffect] : creative_data;
  useEffect(() => {
    if (loaded && creativeDataToShow.length > 0) {
      document.title = `Creative ${creativeDataToShow[0].title}`;
    } else {
      document.title = `Creative ${match.params.id}`;
    }
  }, [creativeDataToShow, match.params.id, loaded]);

  function Display() {
    if (loaded) {
      const getCreativeCard = () => {
        if (creativeDataToShow.length > 0)
          return (
            <CreativeCard
              creative_data={creativeDataToShow[0]}
              params={makeQuery(params)}
              authUser={authUser}
              hideHistoryButton
            />
          );
        else return null;
      };
      const getTabs = () => {
        if (creative_data.control)
          return (
            <Tabs value={showControlEffect} onChange={(event, newValue) => setShowControlEffect(newValue)} centered>
              <Tab label="All" value="total" />
              <Tab label="Test" value="effect" />
              <Tab label="Control" value="control" />
            </Tabs>
          );
        else return null;
      };
      return (
        <div>
          {getTabs()}
          {getCreativeCard()}
          {event_data.map((data, index) => {
            const dataToShow = data.control || data.effect ? data[showControlEffect] : data;
            if (!dataToShow) {
              return null;
            }
            dataToShow.event_obj = data.event_obj;
            return (
              <EventCard key={index} event_data={dataToShow} authUser={authUser} notPrimaryClicks params={params} />
            );
          })}
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load data for this creative.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder params={params} setParams={setExtraParams} authUser={authUser} />
      <Display />
    </div>
  );
}

export default function Creatives({ match, authUser }) {
  return (
    <div>
      <Route path={`${match.path}/:id`} render={(props) => <OneCreative {...props} authUser={authUser} />} />
      <Route exact path={match.path} render={(props) => <AllCreatives {...props} authUser={authUser} />} />
    </div>
  );
}
