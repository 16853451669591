import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LeftNav from './LeftNav';
import Summary from './Summary';
import Events from './Events';
import Creatives from './Creatives';
import Days from './Days';
import Transactions from './Transactions';
import Web from './Web';
import CategoryExposure from './CategoryExposure';
import TagExposure from './TagExposure';
import CustomerJourneys from './CustomerJourneys';
import CustDashboard from './CustDashboard';
import { Router, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { createBrowserHistory } from 'history';
import FireBase from './Firebase';
import SignIn from './SignIn';
import PrivateRoute from './PrivateRoute';

const history = createBrowserHistory();
const firebase = new FireBase();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
}));

function App() {
  const classes = useStyles();
  const [authUser, setAuthUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  useEffect(() => {
    firebase.auth.onAuthStateChanged((newAuthUser) => {
      if (newAuthUser) {
        newAuthUser.getIdToken(true).then((token) => {
          document.cookie = 'token=' + token;
          setAuthUser(newAuthUser);
          setLoadingAuth(false);
        });
      } else {
        setLoadingAuth(false);
      }
    });
  });

  if (loadingAuth) {
    return <p>Loading..</p>;
  }

  return (
    <div className={classes.root}>
      <Router history={history}>
        <LeftNav history={history} />
        <Container className={classes.content}>
          <Route path="/signin" render={(props) => <SignIn {...props} authUser={authUser} firebase={firebase} />} />
          <PrivateRoute exact path="/" component={CustDashboard} authUser={authUser} />
          <PrivateRoute path="/summary" component={Summary} authUser={authUser} />
          <PrivateRoute path="/events" component={Events} authUser={authUser} />
          <PrivateRoute path="/creatives" component={Creatives} authUser={authUser} />
          <PrivateRoute path="/days" component={Days} authUser={authUser} />
          <PrivateRoute path="/transactions" component={Transactions} authUser={authUser} />
          <PrivateRoute path="/web" component={Web} authUser={authUser} />
          <PrivateRoute path="/category_exposure" component={CategoryExposure} authUser={authUser} />
          <PrivateRoute path="/tag_exposure" component={TagExposure} authUser={authUser} />
          <PrivateRoute path="/customer_journeys" component={CustomerJourneys} authUser={authUser} />
        </Container>
      </Router>
    </div>
  );
}

export default App;
