import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { QueryBuilder, parseQuery, makeQuery } from './QueryBuilder/QueryBuilder';
import useFetch from './useFetch';
import { BASE_URL } from './Constants';
import WebResults from './WebResults';

export default function Web({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Web';
  });

  const params = parseQuery(history);

  const setExtraParams = (newParams) => {
    history.push(`?${makeQuery(newParams)}`);
  };

  const url = `${BASE_URL}/get_web?${makeQuery(params)}`;

  const [{ summary }, loaded, errorLoading] = useFetch(url, [], authUser);
  function Display() {
    if (loaded) {
      return (
        <div>
          <WebResults data={summary} authUser={authUser} />
        </div>
      );
    } else if (errorLoading) {
      return (
        <Typography varient="h3" color="error">
          Unable to load web data.
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  }

  return (
    <div>
      <QueryBuilder
        params={params}
        setParams={setExtraParams}
        audienceUrl="list_web_audiences"
        hideEventTags
        hideContentBlock
        authUser={authUser}
      />
      <Display />
    </div>
  );
}
