// @format
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { AudiencePanel } from './Audience';
import { EventTagPanel } from './EventTag.js';
import { CreativeTagPanel } from './CreativeTag.js';
import { ExperimentPanel } from './Experiment.js';
import { ComparisonPanel } from './Comparison';
import { ContentBlockPanel } from './ContentBlock';
import { DateRangePanel } from './DateRange';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function QueryEditDialog({
  params,
  setParams,
  open,
  onSave,
  onCancel,
  hideDates,
  hideEventTags,
  hideContentBlock,
  experimentChoices,
  audienceChoices,
  eventTagChoices,
  creativeTagChoices,
  contentBlockChoices,
}) {
  const classes = useStyles();

  const getSelected = (param) => audienceChoices.filter((x) => param[x.id]);
  const selectedAudiences = getSelected(params.audience);
  const selectedControl = getSelected(params.comparison.control);
  const selectedEffect = getSelected(params.comparison.effect);

  const handleChange = (parameter_name) => (new_value) => {
    const newParams = { ...params, [parameter_name]: new_value };
    setParams(newParams);
  };

  const escFunction = React.useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onCancel();
      }
    },
    [onCancel]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <Dialog fullScreen open={open} className={classes.root}>
      <ExperimentPanel
        experiments={params.experiments}
        experimentChoices={experimentChoices}
        handleChange={handleChange}
      />
      <ContentBlockPanel
        hideContentBlock={hideContentBlock}
        primary={params.primary}
        notPrimary={params.notPrimary}
        contentBlockChoices={contentBlockChoices}
        contentBlocks={params.contentBlocks}
        handleChange={handleChange}
      />
      <EventTagPanel
        tags={params.tags}
        tagChoices={eventTagChoices}
        handleChange={handleChange}
        excluded_tags={params.excluded_tags}
        hideEventTags={hideEventTags}
      />
      <CreativeTagPanel
        tags={params.creative_tags}
        tagChoices={creativeTagChoices}
        handleChange={handleChange}
        excluded_tags={params.excluded_creative_tags}
      />
      <AudiencePanel
        selectedAudiences={selectedAudiences}
        audience={params.audience}
        handleChange={handleChange}
        audienceChoices={audienceChoices}
      />
      <ComparisonPanel
        params={params.comparison}
        onParamChange={handleChange('comparison')}
        audienceChoices={audienceChoices}
        selectedControl={selectedControl}
        selectedEffect={selectedEffect}
      />
      {!hideDates && (
        <DateRangePanel
          start_date={params.start_date}
          end_date={params.end_date}
          excluded_dates={params.excluded_dates}
          handleChange={handleChange}
        />
      )}
      <ButtonGroup variant="contained">
        <Button autoFocus onClick={() => onSave()}>
          Save
        </Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </ButtonGroup>
    </Dialog>
  );
}
