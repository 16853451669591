// @format
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

export function ContentBlockDisplay({
  primary,
  notPrimary,
  contentBlockChoices,
  contentBlocks,
  hideContentBlock,
  alwaysShow,
}) {
  const classes = useStyles();
  if (!alwaysShow && (hideContentBlock || !(primary || notPrimary || (Object.keys(contentBlocks).length > 0)))) return null;
  else
    return (
      <div className={classes.display}>
        <Typography variant="h6">Content Block</Typography>
        {primary ? (
          <Chip label="Primary" className={classes.chip} />
        ) : notPrimary ? (
          <Chip label="Not Primary" className={classes.chip} />
        ) : (
          contentBlockChoices
            .filter((x) => contentBlocks[x.id])
            .map((contentBlock, index) => <Chip key={index} label={contentBlock.title} className={classes.chip} />)
        )}
      </div>
    );
}

export function ContentBlockPanel({
  hideContentBlock,
  primary,
  notPrimary,
  contentBlockChoices,
  contentBlocks,
  handleChange,
}) {
  if (hideContentBlock) return null;
  else
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <ContentBlockDisplay
            primary={primary}
            notPrimary={notPrimary}
            contentBlockChoices={contentBlockChoices}
            contentBlocks={contentBlocks}
            alwaysShow
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ContentBlockSelector
            primary={primary}
            setPrimary={handleChange('primary')}
            notPrimary={notPrimary}
            setNotPrimary={handleChange('notPrimary')}
            contentBlocks={contentBlocks}
            setContentBlocks={handleChange('contentBlocks')}
            contentBlockChoices={contentBlockChoices}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
}

export default function ContentBlockSelector({
  primary,
  setPrimary,
  notPrimary,
  setNotPrimary,
  contentBlocks,
  setContentBlocks,
  contentBlockChoices,
}) {
  const handleContentBlockChange = (contentBlock) => (event) => {
    setContentBlocks({ ...contentBlocks, [contentBlock['id']]: event.target.checked });
  };
  const handlePrimaryChange = (x, setX) => () => {
    setX(!x);
  };

  if (contentBlockChoices.length === 0) {
    return null;
  } else {
    return (
      <div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox checked={primary} onChange={handlePrimaryChange(primary, setPrimary)} disabled={notPrimary} />
            }
            label="Primary"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={notPrimary}
                onChange={handlePrimaryChange(notPrimary, setNotPrimary)}
                disabled={primary}
              />
            }
            label="Not Primary"
          />
        </FormGroup>
        <FormGroup row>
          {contentBlockChoices.map((contentBlock, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={!(primary || notPrimary) && (contentBlocks[contentBlock['id']] || false)}
                  onChange={handleContentBlockChange(contentBlock)}
                  value={contentBlock['contentblock_id']}
                  disabled={primary || notPrimary}
                />
              }
              label={contentBlock['title']}
            />
          ))}
        </FormGroup>
      </div>
    );
  }
}
