// @format
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TagSelector = ({ tags, setTags, tagChoices }) => {
  const handleChange = (tag) => (event) => {
    setTags({ ...tags, [tag['tag_id']]: event.target.checked });
  };

  if (tagChoices.length === 0) {
    return null;
  } else {
    return (
      <div>
        <FormGroup row>
          {tagChoices.map((tag, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox checked={tags[tag['tag_id']] || false} onChange={handleChange(tag)} value={tag['tag_id']} />
              }
              label={tag['name']}
            />
          ))}
        </FormGroup>
      </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  display: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

export function CreativeTagDisplay({ tagChoices, tags, alwaysShow, excluded }) {
  const classes = useStyles();
  const currentTags = tagChoices.filter((x) => tags[x.tag_id]);
  return currentTags.length > 0 || alwaysShow ? (
    <div className={classes.display}>
      <Typography variant="h6">{excluded ? 'Excluded' : ''} Creative Tags</Typography>
      {currentTags.map((tag, index) => (
        <Chip key={index} label={tag.name} className={classes.chip} />
      ))}
    </div>
  ) : null;
}

export function CreativeTagPanel({ tags, tagChoices, handleChange, excluded_tags, hideCreativeTags }) {
  if (hideCreativeTags) return null;
  else
    return (
      <React.Fragment>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <CreativeTagDisplay tagChoices={tagChoices} tags={tags} alwaysShow />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TagSelector tags={tags} setTags={handleChange('creative_tags')} tagChoices={tagChoices} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <CreativeTagDisplay tagChoices={tagChoices} tags={excluded_tags} alwaysShow excluded />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TagSelector tags={excluded_tags} setTags={handleChange('excluded_creative_tags')} tagChoices={tagChoices} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
}
