import { useState, useEffect } from 'react';

export default function useFetch(url, defaultData, authUser) {
  const [data, updateData] = useState(defaultData);
  const [doneFetching, updateDoneFetching] = useState(false);
  const [errorFetching, updateErrorFetching] = useState(false);

  useEffect(() => {
    if (!url) {
      return;
    }

    const abortController = new AbortController();
    async function fetchData() {
      updateDoneFetching(false);
      try {
        if (authUser) {
          const token = await authUser.getIdToken();
          document.cookie = 'token=' + token;
        }
        const resp = await fetch(url, { signal: abortController.signal, credentials: 'include' });
        const json = await resp.json();
        updateData(json);
        updateDoneFetching(true);
      } catch (ex) {
        if (ex.name === 'AbortError') {
          return;
        }
        updateErrorFetching(true);
        throw ex;
      }
    }
    fetchData();
    return () => abortController.abort();
  }, [url, authUser]);

  return [data, doneFetching, errorFetching];
}
