import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MetricMaker from './MetricMaker';

const useStyles = makeStyles((theme) => ({
  single: {
    // minWidth: '500px',
  },
}));

function Single({ effect_data, control_data, authUser }) {
  const classes = useStyles();
  const Metric = MetricMaker({ effect_data, control_data, authUser, xs: 6 });

  if (!effect_data) return null;

  return (
    <div className={classes.single}>
      <Grid spacing={2} container>
        <Metric title="Impressions" tooltip="" base={(effect_data.recs || 0).toLocaleString()} hideLift />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Clicks"
          tooltip=""
          base={(effect_data.clicks || 0).toLocaleString()}
          valComputer={(x) => x.clicks}
          varComputer={(x) => (x.clicks / x.recs) * (1 - x.clicks / x.recs)}
          varValComputer={(x) => x.clicks / x.recs}
          countComputer={(x) => x.recs}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Click Rate"
          tooltip=""
          base={(effect_data.clicks / effect_data.recs).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.clicks / x.recs}
          varComputer={(x) => (x.clicks / x.recs) * (1 - x.clicks / x.recs)}
          countComputer={(x) => x.recs}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Direct Orders"
          tooltip=""
          base={(effect_data.direct_orders || 0).toLocaleString()}
          valComputer={(x) => x.direct_orders}
          varComputer={(x) => (x.direct_orders / x.recs) * (1 - x.direct_orders / x.recs)}
          varValComputer={(x) => x.direct_orders / x.recs}
          countComputer={(x) => x.recs}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Direct Order Rate"
          tooltip=""
          base={(effect_data.direct_orders / effect_data.recs).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.direct_orders / x.recs}
          varComputer={(x) => (x.direct_orders / x.recs) * (1 - x.direct_orders / x.recs)}
          countComputer={(x) => x.recs}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Orders"
          tooltip=""
          base={(effect_data.orders || 0).toLocaleString()}
          valComputer={(x) => x.orders}
          varComputer={(x) => (x.orders / x.recs) * (1 - x.orders / x.recs)}
          varValComputer={(x) => x.orders / x.recs}
          countComputer={(x) => x.recs}
          totalFormater={(x) => x.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          showTotalLift
        />
        <Metric
          title="Order Rate"
          tooltip=""
          base={(effect_data.orders / effect_data.recs).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          valComputer={(x) => x.orders / x.recs}
          varComputer={(x) => (x.orders / x.recs) * (1 - x.orders / x.recs)}
          countComputer={(x) => x.recs}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Revenue"
          tooltip=""
          base={(effect_data.amount || 0).toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          valComputer={(x) => x.amount}
          varComputer={(x) => x.amount_per_rec_var}
          varValComputer={(x) => x.amount / x.recs}
          countComputer={(x) => x.recs}
          totalFormater={(x) => x.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          showTotalLift
        />
        <Metric
          title="Revenue Per Impression"
          tooltip=""
          base={(effect_data.amount / effect_data.recs).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
          valComputer={(x) => x.amount / x.recs}
          varComputer={(x) => x.amount_per_rec_var}
          countComputer={(x) => x.recs}
        />
      </Grid>
      <Divider />
      <Grid spacing={2} container>
        <Metric
          title="Direct Revenue"
          tooltip=""
          base={(effect_data.direct_amount || 0).toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          valComputer={(x) => x.direct_amount}
          varComputer={(x) => x.amount_per_rec_var}
          varValComputer={(x) => x.direct_amount / x.recs}
          countComputer={(x) => x.recs}
          totalFormater={(x) => x.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
          showTotalLift
        />
        <Metric
          title="Direct Revenue Per Impression"
          tooltip=""
          base={(effect_data.direct_amount / effect_data.recs).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
          valComputer={(x) => x.direct_amount / x.recs}
          varComputer={(x) => x.amount_per_rec_var}
          countComputer={(x) => x.recs}
        />
      </Grid>
      <Divider />
    </div>
  );
}

export default function WebResults({ data, hideTitles, authUser }) {
  const classes = useStyles();
  const getTitle = (x) => (hideTitles ? null : <Typography variant="h6">{x}</Typography>);
  if (data.control)
    return (
      <Grid container spacing={8} className={classes.root}>
        <Grid item lg={6} xs={12}>
          {getTitle('Test')}
          <Single effect_data={data['effect']} control_data={data['control']} authUser={authUser} />
        </Grid>
        <Grid item lg={6} xs={12}>
          {getTitle('Control')}
          <Single effect_data={data['control']} authUser={authUser} />
        </Grid>
      </Grid>
    );
  else return <Single effect_data={data} />;
}
