// @format
import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useFetch from './useFetch';
import { BASE_URL, LOGOS } from './Constants';
import UnattributedResults from './UnattributedResults';
import EmailResults from './EmailResults';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'auto',
    margin: '10px',
  },
  cardMedia: {
    maxWidth: '220px',
    objectFit: 'contain',
    textAlign: 'center',
    padding: theme.spacing(3, 3),
    backgroundColor: 'white',
  },
  datepicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  datepickerContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  hidden: {
    color: theme.palette.background.paper,
  },
}));

function Client({ client_id, authUser, logo, children, hideUnattributed, showWeb }) {
  const classes = useStyles();
  const [childIndex, setChildIndex] = React.useState(0);
  const oneMonthAgo = moment().subtract(30, 'days');
  const [start_date, setStartDate] = React.useState(children[childIndex].props.start_date || oneMonthAgo);
  const [end_date, setEndDate] = React.useState(children[childIndex].props.end_date || moment());
  let elements = React.Children.toArray(children).map((x) =>
    React.cloneElement(x, {
      client_id: client_id,
      authUser: authUser,
      hideUnattributed: hideUnattributed,
      start_date: start_date,
      end_date: end_date,
      showWeb: showWeb,
    })
  );

  const handleTabChange = (newValue) => {
    setChildIndex(newValue);
    setStartDate(children[newValue].props.start_date || oneMonthAgo);
    setEndDate(children[newValue].props.end_date || moment());
  };
  return (
    <Card raised className={classes.card} id={client_id}>
      <CardMedia className={classes.cardMedia} component="img" image={logo} />
      <div className={classes.hidden}>{client_id}</div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.datepickerContainer}>
          <div className={classes.datepicker}>
            <DatePicker
              label="Start Date"
              value={start_date}
              onChange={setStartDate}
              autoOk
              maxDate={moment.min(moment(), end_date)}
              minDate={moment().subtract(3, 'years')}
              format="MMMM Do YYYY"
            />
          </div>
          <div className={classes.datepicker}>
            <DatePicker
              label="End Date"
              value={end_date}
              onChange={setEndDate}
              autoOk
              minDate={moment.max(moment().subtract(365, 'days'), start_date)}
              maxDate={moment()}
              format="MMMM Do YYYY"
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
      {children.length > 0 && (
        <Tabs value={childIndex} onChange={(event, newValue) => handleTabChange(newValue)} centered>
          {children.map((child, index) => (
            <Tab label={child.props.title} value={index} key={index} />
          ))}
        </Tabs>
      )}
      {elements[childIndex]}
    </Card>
  );
}

function Experiment({
  client_id,
  effect,
  control,
  excluded_tags,
  tags,
  experiments,
  audience_ids,
  environment,
  authUser,
  start_date,
  end_date,
  hideUnattributed,
}) {
  const classes = useStyles();
  const searchParams = new URLSearchParams('');
  environment = environment || 'reporting_cache';
  searchParams.set('client_id', client_id);
  searchParams.set('environment', environment);
  searchParams.set('control_audience_ids', control);
  searchParams.set('effect_audience_ids', effect);
  searchParams.set('primary', true);
  searchParams.set('excluded_tags', excluded_tags || []);
  searchParams.set('tags', tags || []);
  searchParams.set('experiments', experiments || []);
  searchParams.set('audience_ids', audience_ids || []);
  searchParams.set('start_date', start_date.format('YYYY-MM-DD'));
  searchParams.set('end_date', end_date.format('YYYY-MM-DD'));
  const params = searchParams.toString();

  return (
    <React.Fragment>
      <CardContent className={classes.cardContent}>
        <Email params={params} client_id={client_id} authUser={authUser} />
        {hideUnattributed || true ? null : <Unattributed params={params} client_id={client_id} authUser={authUser} />}
      </CardContent>
      <CardActions>
        {hideUnattributed || true ? null : (
          <Button color="secondary" component={Link} to={`/transactions?${params}&audience_ids=2000`}>
            Transactions
          </Button>
        )}
        <Button color="secondary" component={Link} to={`/events?${params}`}>
          Email Events
        </Button>
        <Button color="secondary" component={Link} to={`/creatives?${params}`}>
          Email Creatives
        </Button>
        <Button color="secondary" component={Link} to={`/days?${params}`}>
          Email Days
        </Button>
      </CardActions>
    </React.Fragment>
  );
}

function Email({ params, client_id, authUser }) {
  const [summary_data, loaded, errorLoading] = useFetch(`${BASE_URL}/get_email_summary?${params}`, [], authUser);
  if (loaded && summary_data.effect) {
    const last_updated = summary_data.effect.last_updated;
    return (
      <div>
        {last_updated ? (
          <span>Last Updated: {moment(last_updated + '+00:00').format('dddd, MMMM Do YYYY, h:mm:ss a')}</span>
        ) : null}
        <Typography variant="h4">Email Attributed</Typography>
        <EmailResults
          data={summary_data}
          authUser={authUser}
          hideEffectiveness
          allTitle={'All'}
          directTitle={'Primary'}
        />
      </div>
    );
  } else if (errorLoading) {
    return (
      <Typography varient="h3" color="error">
        Unable to load email data for client {client_id}.
      </Typography>
    );
  } else {
    return <CircularProgress />;
  }
}

function Unattributed({ params, client_id, authUser }) {
  const [{ summary }, loaded, errorLoading] = useFetch(
    `${BASE_URL}/get_unattributed?${params}&audience_ids=2000`,
    [],
    authUser
  );
  if (loaded) {
    return (
      <div>
        <Typography variant="h4">Unattributed</Typography>
        <UnattributedResults data={summary} authUser={authUser} />
      </div>
    );
  } else if (errorLoading) {
    return (
      <Typography varient="h3" color="error">
        Unable to load transaction data for client {client_id}.
      </Typography>
    );
  } else {
    return <CircularProgress />;
  }
}

export default function CustDashboard({ match, history, authUser }) {
  useEffect(() => {
    document.title = 'Performance Dashboard';
  });
  new URLSearchParams(history.location.search);
  const [params, setParams] = React.useState(new URLSearchParams(history.location.search));
  history.listen((location, action) => {
    setParams(new URLSearchParams(location.search));
  });
  const [configs, loaded, errorLoading] = useFetch(`${BASE_URL}/get_homepage_configs?${params}`, [], authUser);

  if (loaded) {
    const client_configs = {};
    configs.map((config) => {
      const client = config.client_id;
      if (client_configs[client] == null) client_configs[client] = [];
      client_configs[client].push(config.data);

      return null;
    });

    var client_ids = Object.keys(client_configs);
    client_ids.sort();

    const environment = params.get('environment') || 'reporting_cache';
    console.log(client_configs);

    return (
      <div>
        {client_ids.map((client_id, index) => (
          <Client client_id={client_id} logo={LOGOS[client_id]} key={index} authUser={authUser} hideUnattributed>
            {client_configs[client_id].map((experiment, experiment_index) => (
              <Experiment
                key={experiment_index}
                title={experiment.Title}
                effect={experiment.EffectAudienceIds}
                control={experiment.ControlAudienceIds}
                excluded_tags={experiment.ExcludedTags}
                tags={experiment.Tags}
                audience_ids={experiment.AudienceIds}
                experiments={experiment.Experiments}
                environment={environment}
                start_date={experiment.StartDate ? moment(experiment.StartDate) : undefined}
                end_date={experiment.EndDate ? moment(experiment.EndDate) : undefined}
              />
            ))}
          </Client>
        ))}
      </div>
    );
  } else if (errorLoading) {
    return (
      <Typography varient="h3" color="error">
        Unable to load data.
      </Typography>
    );
  } else {
    return <CircularProgress />;
  }
}
